@import url(https://fonts.googleapis.com/css2?family=Oxygen+Mono&family=Raleway:wght@400;700;900&display=swap);
html, body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{text-align:center;background-color:#ffe2d1;height:100vh}.App.--centred{display:flex}.App h1{font-weight:900;font-size:70px;margin:0 auto 24px auto;padding-top:64px;text-align:left;width:450px}.btnLink{text-decoration:none;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;padding:12px 16px;border-radius:12px;font-weight:600}.btnLink.--primary{background-color:#ff5e01;color:#fff9f5}.btnLink.--secondary{border:2px solid #ff5e01;color:#ff5e01}.body.--centred{margin:auto}
.footer{width:100%;height:60px;box-sizing:border-box;background-color:#ff5e01;display:flex;align-items:center;box-sizing:border-box;padding:12px 16px}.footer>.title{margin:0 0 0 auto;font-size:32px;color:#fff9f5;font-weight:900}.controls{display:flex;align-items:center;justify-content:center}.controls>button{border:0;background:none}.controls>button>.icon{width:32px;height:32px}.controls>button>.icon>path{color:#fff9f5}.controls>.slides{margin:0 40px;display:flex;align-items:center}.controls>.slides>.icon{margin-right:8px}.controls>.slides>.icon>path{color:#fff9f5}.controls>.slides>p{font-size:24px;font-weight:600;font-family:"Oxygen Mono",monospace;color:#fff9f5}.timer{display:flex;justify-content:center;align-items:center;position:relative;margin:0 auto 0 0}.timer>svg{position:absolute;width:100%;height:100%;overflow:visible;top:0;left:0;transform:rotateY(-180deg) rotateZ(-90deg)}.timer>p{font-size:20px;font-weight:800;font-family:"Oxygen Mono",monospace;color:#fff9f5}
.image>img{margin:0 auto;display:block;height:calc(100vh - 60px);width:100vw;width:auto;object-fit:cover}.attribution{position:fixed;top:0;right:0;background-color:#ff5e01;padding:8px 12px}.attribution .icon{margin-right:8px}.attribution a{color:#000}
.setupForm{width:450px;margin:0 auto;display:flex;flex-direction:column;align-items:flex-start}.inputContainer{display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start}.inputLabel{text-align:left}.radioContainer{padding:8px 0;display:flex}.radioContainer>input{display:none}.radioContainer input:checked+label{background-color:#ff5e01}.radioContainer>label{display:block;height:30px;padding:12px 24px;border-radius:8px;margin:8px;background-color:#fff9f5;display:flex;align-items:center;transition:background-color .5s ease-in-out;font-weight:bold;font-family:"Oxygen Mono",monospace}.btnLink{align-self:flex-end;text-decoration:none;color:#ff5e01;font-size:24px;font-weight:900;cursor:pointer;display:flex;align-items:center}.btnLink .icon{width:20px;height:20px;padding-left:4px}
.body{display:flex;flex-direction:column;align-items:center}.body>p{margin-top:0;font-size:60px;color:rgba(0,0,0,.8);font-weight:800}.body .icon{height:200px;width:200px;color:rgba(0,0,0,.8)}.buttonWrapper{margin:100px auto 0 auto;display:flex;width:400px;justify-content:space-around}
