@import "./Variables.scss";

.footer {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    background-color: map-get($colors-primary, "medium");
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 16px;

    > .title {
        margin: 0 0 0 auto;
        font-size: 32px;
        color: map-get($colors-text, "light");
        font-weight: 900;
    }
}

.controls {

    display: flex;
    align-items: center;
    justify-content: center;

    > button {

        border: 0;
        background: none;

        > .icon {
            width: 32px;
            height: 32px;
            
            > path {
                color: map-get($colors-text, "light");
            }
        }
    }

    > .slides {

        margin: 0 40px;
        display: flex;
        align-items: center;

        > .icon {
            margin-right: 8px;

            > path {
                color: map-get($colors-text, "light");
            }
        }

        > p {
            font-size: 24px;
            font-weight: 600;
            @include font-number;
            color: map-get($colors-text, "light");
        }
    }
}

.timer {

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto 0 0;

    > svg {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: visible;
        top: 0;
        left: 0;
        transform: rotateY(-180deg) rotateZ(-90deg);
    }

    > p {
        font-size: 20px;
        font-weight: 800;
        @include font-number;
        color: map-get($colors-text, "light");
    }
}

