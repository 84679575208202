@import "./Variables.scss";

.setupForm {
  width: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.inputLabel {
  text-align: left;
}

.radioContainer {
  padding: 8px 0;
  display: flex;

  > input {
    display: none;
  }

  input:checked + label {
    background-color: map-get($colors-primary, "medium");
  }

  > label {
    display: block;
    height: 30px;
    padding: 12px 24px;
    border-radius: 8px;
    margin: 8px;
    background-color: map-get($colors-text, "light");
    display: flex;
    align-items: center;
    transition: background-color 0.5s ease-in-out;
    font-weight: bold;
    @include font-number;
  }
}

.btnLink {
  align-self: flex-end;
  text-decoration: none;
  color: map-get($colors-primary, "medium");
  font-size: 24px;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;

  .icon {
    width: 20px;
    height: 20px;
    padding-left: 4px;
  }
}