@import "./Variables.scss";

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  > p {
    margin-top: 0;
    font-size: 60px;
    color: map-get($colors-text, "dark");
    font-weight: 800;
  }

  .icon {
    height: 200px;
    width: 200px;
    color: map-get($colors-text, "dark");
  }
}

.buttonWrapper {
  margin: 100px auto 0 auto;
  display: flex;
  width: 400px;
  justify-content: space-around;
}