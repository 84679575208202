@import "./Variables.scss";

.App {
  text-align: center;
  background-color: map-get($colors-primary, "light");
  height: 100vh;

  &.--centred {
    display: flex;
  }

  h1 {
    font-weight: 900;
    font-size: 70px;
    margin: 0 auto 24px auto;
    padding-top: 64px;
    text-align: left;
    width: 450px;
  }
}

.btnLink {

  text-decoration: none;
  width: fit-content;
  padding: 12px 16px;
  border-radius: 12px;
  font-weight: 600;

  &.--primary {
    
    background-color: map-get($colors-primary, "medium");
    color: map-get($colors-text, "light");
  }

  &.--secondary {
    border: 2px solid map-get($colors-primary, "medium");
    color: map-get($colors-primary, "medium");
  }

  &.--tertiary {

  }
}

.body {

  &.--centred {
    margin: auto;
  }
}